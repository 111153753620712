/* You can add global styles to this file, and also import other style files */


@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/dist/css/bootstrap.css";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "styles/theme.scss";

@import "styles/column-layout.scss";
@import "styles/angular-calendar.scss";
/*
@import "ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin";
*/

@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

/*

@font-face {
  font-family: openSans;
  src: url("/assets/fonts/OpenSans-Regular.ttf");
}


@font-face {
  font-family: RobotoCondensed;
  src: url("/assets/fonts/RobotoCondensed-Regular.ttf");
}

*/

html{
  height: 100%;
}


body {
  height: 100%;
  background-color: $my-app-bg;
  margin: 0;
}

app-root {
  display: flex;
  position: absolute;
  margin-top: env(safe-area-inset-top);
  top: 60px;
  left: 0;
  bottom: 0;
  right: 0;
}

.test {
  color: #ffffff;
}

.mat-tab-label {
  min-width: 90px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00a19a !important;
  height: 3px;
}

.unavailable>mwl-calendar-day-view-event>.cal-event {
  border-radius: 0px !important;
  border: none;
  cursor: no-drop;
}

.available>mwl-calendar-day-view-event>.cal-event {
  border-radius: 0px !important;
  border: none;
}

.date-controls>.mat-button-toggle>.mat-button-toggle-button>.mat-button-toggle-label-content {
  line-height: 27px !important;
}

.mat-list-base .mat-list-item.mat-2-line,
.mat-list-base .mat-list-option.mat-2-line {
  height: 45px !important;
}

/* retro css for the diary */
.cal-day-view .cal-event {
  color: #000000;
  text-overflow: ellipsis;
  font-size: 9px !important;
  font-weight: 400;
  /* font-family: RobotoCondensed; */
}

.cal-event .text-center {
  font-size: 11px !important;
  font-weight: 400;
 /* font-family: RobotoCondensed; */
}


.cal-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 2px !important;
  padding: 1px 0 !important;
}

.cal-week-view .cal-event {
  font-size: 11px !important;
 /*  font-family:RobotoCondensed; */
 font-weight: 400;
  width: 50px;
}

//Time Column on diary
#diaryDiv > div.ng-star-inserted > app-mwl-day-view-scheduler > div > div.cal-time-events > div.cal-time-label-column.ng-star-inserted > div > mwl-calendar-week-view-hour-segment > div > div{
  text-align: left;
}
#diaryDiv > div.ng-star-inserted > app-mwl-day-view-scheduler > div > div.cal-day-headers{
  padding-left: 50px;
}
#diaryDiv>div.ng-star-inserted>app-mwl-day-view-scheduler>div>div.cal-time-events>div.cal-time-label-column.ng-star-inserted {
  width: 50px;
}

@media only screen and (max-device-width: 860px) {
  #diaryDiv > div.ng-star-inserted > app-mwl-day-view-scheduler > div > div.cal-day-headers{
    padding-left: 20px;
  }
  #diaryDiv>div.ng-star-inserted>app-mwl-day-view-scheduler>div>div.cal-time-events>div.cal-time-label-column.ng-star-inserted {
    width: 20px;
  }
}

//End of Time Column on diary

mat-form-field.full-width .mat-form-field-infix {
  width: 360px;
}

@media print {
  .no-print {
    display: none;
    visibility: collapse;
  }

  .container-fluid{
    width: 100%;
  }

  .print-w-100{
    width: 100% !important;
  }
  
  .ag-layout-normal {
    display: none;
  }

  .ag-root-wrapper,
  .ag-root-wrapper-body,
  .ag-root,
  .ag-body-viewport,
  .ag-center-cols-container,
  .ag-center-cols-viewport,
  .ag-center-cols-clipper,
  .ag-body-horizontal-scroll-viewport,
  .ag-virtual-list-viewport {
    height: 100% !important;
    overflow: visible !important;
    display: block !important;
    position: initial !important;
    top: initial !important;
    left: initial !important;
    bottom: initial !important;
    right: initial !important;
  }

  html,
  body,
  .main-display,
  .mat-drawer-content,
  .mat-drawer-container {
    height: 100% !important;
    overflow: visible !important;
    display: block !important;
    position: initial !important;
    top: initial !important;
    left: initial !important;
    bottom: initial !important;
    right: initial !important;
  }
}

/* Calendar */
.cal-day-columns {
  background-color: none;
  transition: all 0.1s ease-in-out;
}

.cal-day-columns .bg-available {
  transition: all 0.001s ease-in-out;
  background-color: rgb(127, 255, 127) !important;
  border-color: rgb(107, 205, 107) !important;
}

.dark-mode{
  .cal-day-columns .bg-available {
    background-color: rgb(27,55,27) !important;
    border-color: rgb(87,105,87) !important;
  }
  
}

.cal-events-container {
  z-index: 9999;
}

.cal-event {
  text-align: center;
  color: #000000 !important;
}

.cal-current-time-marker {
  height: 3px;
  background: linear-gradient(270deg, #ec268d, #1f55dd);
  background-size: 400% 400%;
  animation: BackgroundFade 5s ease infinite;

  @keyframes BackgroundFade {
    0% {
      background-position: 0 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }
}

/* this adds a scroll bar ....

.mat-menu-panel {
  &.mouseleave {
    .mat-menu-content {
      padding: 0;
      > div {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}


.ag-theme-material {
  @include ag-theme-material((odd-row-background-color: rgba(6, 109, 62, .1),
      cell-horizontal-padding: ag-derived(grid-size, $times: 0.5),
      borders: true,
      borders-critical: ag-derived(borders),
      borders-secondary: ag-derived(borders),
      borders-side-button: ag-derived(borders),
      border-color: rgba(0, 0, 0, .1),
      secondary-border-color: ag-derived(border-color),
      row-border-color: ag-derived(secondary-border-color),
      grid-size: 6px,
      header-background-color: whitesmoke,
      header-foreground-color: rgba(0, 0, 0, 0.9),
      range-selection-border-color: transparent));
}
*/
.flashing {
  animation: glowing alternate 3000ms infinite;
}

.cancelled {
  text-decoration: line-through;
}

@keyframes glowing {
  0% {
    filter: invert(100%);
    //  box-shadow: 0 0 20px white;
  }

  100% {
    // opacity: 100%;
    filter: invert(0%);
    //  transform: rotate(360deg) scale(1.5);
    //  box-shadow: 0 0 50px rgb(255,0,0);
  }

}

.cal-day-headers {
  position: sticky;
  z-index: 20000;
  border-top: 2px solid #e1e1e1;
  top: 0px;
}

.notesInAppointment {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 2px;
  color: rgba(0, 0, 0, .75) !important;
  line-height: normal;
  font-size: smaller;
  width: 100%;
  white-space: normal;
}

.cdk-overlay-container {
  z-index: 3000;
}

.loading-card {
  min-height: 40rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide>div {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notes_locked_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(248, 215, 218, .2);
  border: 1px solid #f5c2c7;
  border-radius: 5px;
  top: 0;
  left: 0;

  button {
    position: absolute;
    right: 3.5rem;
    top: -0.2rem;
    color: #842029;
  }

  mat-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #842029;
  }
}

.notes_change_requested {
  color: #ffc107;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 1.2rem 3rem;
  float: right;
}

.notes_change_requested_overlay {
  border: 1px solid #ffc107 !important;
  background-color: rgba(255, 193, 7, .2) !important;

  mat-icon {
    color: #ffc107 !important;
  }
}

@media only screen and (min-device-width: 480px) {
  .margin-collapse {
    margin: 1rem 1rem 1rem 1rem !important;
  }
}

@media print {
  #jsd-widget {
    display: none;
  }
}
// Patient questionnaire req patient completes dialog box
.form-list{
  max-height: 400px;
  background:
    /* Shadow Cover TOP */
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ) center top,

    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0),
      white 70%
    ) center bottom,

    /* Shadow TOP */
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center top,

    /* Shadow BOTTOM */
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.cdk-overlay-pane:has(.credit-modal) {
  height: 100%;
}


/* ===== Scrollbar CSS ===== */
 

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #b7c2b8;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #066d3e ;
    border-radius: 2px;
    border: 2px none #033920;
  }

  
::-webkit-scrollbar-thumb:hover {
  background-color: #07854a ;
}



.dark-mode{
  

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background-color: #066d3e ;
  }

  *::-webkit-scrollbar-thumb {
    background: #b7c2b8;
    
    border-radius: 2px;
    border: 2px none #033920;
  }

    
  ::-webkit-scrollbar-thumb:hover {
    background-color: #07854a ;
  }

  .cal-week-view{
    background-color: #000000;
  }

  .cal-week-view .cal-hour-odd {
    background-color: #333333;
  }
}