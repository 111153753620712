@use '@angular/material' as mat; 
//@import "src/theme.scss";

  @media (min-width: 992px) {
    .fix-top{
      position: sticky;
      top: 20px;
      margin-top: 100px;
    }

    .desktop-buttons {
      margin-top: 1.25rem;
    }

    .desktop-button, .desktop-input {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    .fix-top {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: white;
      border-top: 1px solid rgba(map-get(mat.$green-palette, 400), .3);
    }

    .list-sections {
      display: flex;
      justify-content: space-evenly;
    }

    .mobile-margin {
      margin-top: 6rem;
    }

    .mobile-till-margin {
      margin-top: 7rem;
    }

    .mobile-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid rgba(0,0,0,.1);
    }

    .mobile-invoice-buttons {
      padding-top: 1rem;
    }

    .mobile-button {
      margin: 0 .3rem;
      width: 10rem;
    }

    ::ng-deep .mobile-input > div {
      width: 20rem;
      margin: .3rem auto;
    }
  }

  .btn-link{
    color: map-get(mat.$green-palette, 900);
    text-decoration: none;
    text-align: left;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 100%;
  }
  
  .btn-link:hover{
    color: map-get(mat.$green-palette, 400);
  }

  .list-sections{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .list-sections li{
    padding: 0.375em 0;
  }