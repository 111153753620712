@use '@angular/material' as mat;

@include mat.core();


$my-app-primary: mat.define-palette(mat.$green-palette, 900);
$my-app-primary-dark: mat.define-palette(mat.$green-palette, 100);


$my-app-accent: mat.define-palette(mat.$deep-purple-palette, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$red-palette);
$my-app-theme: mat.define-light-theme((
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

$my-app-bg: map-get(mat.$green-palette, 50);

@include mat.core-theme($my-app-theme);
@include mat.button-theme($my-app-theme);

@include mat.datepicker-theme($my-app-theme);

@include mat.all-component-themes($my-app-theme);



$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $my-app-primary-dark,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
  )
);

.dark-mode {
  @include mat.all-component-colors($angular-dark-theme);
}